import { BasilUserSolid } from '@/assets/icons/guide/BasilUserSolid';
import BaseTopNav from '@/components/base/baseTopNav';
import ValueDisplay from '@/components/display/displayValueDisplay';
import PageEnum from '@/enums/pageEnum';
import { ContractOrderStatusEnum } from '@/pages/order/index/components/ContractOrderList';
import RenderUtil from '@/utils/RenderUtil';
import { calculateProfitLoss, calculateProfitLossRatio } from '@/utils/trade';
import { history } from '@@/core/history';
import { useLocation, useModel } from '@@/exports';
import { cn, Image } from '@nextui-org/react';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// 合约分享
export default () => {
  // 基础定义
  const { t } = useTranslation();
  const { state }: any = useLocation();
  const { curItem, currentTab } = state;
  const { user } = useModel('user') || {};
  const { getSocketRowByOrderId, getSocketRowByName } = useModel('socket');
  const { appInfo } = useModel('appInfo');
  const soketData = getSocketRowByName(curItem?.symbol);
  const { currentUpClass, currentDownClass } = useModel('system');
  const posterRef = useRef<HTMLDivElement>(null);
  // 价格精度
  const priceAccuracy = curItem?.priceAccuracy ?? 6;
  const orderSocket = getSocketRowByOrderId(curItem.id);
  const isHistory = useMemo(() => {
    return currentTab === ContractOrderStatusEnum.HISTORY;
  }, [currentTab]);

  // 盈亏比
  const plRatio = isHistory
    ? curItem?.plRatio
    : // getContractProfitAndLoss(curItem?.id)?.plRatio || curItem?.plRatio;
      calculateProfitLossRatio({
        marketPrice: soketData?.a || orderSocket?.currentPrice,
        openPrice: curItem?.endPrice,
        leverage: orderSocket?.lever ?? curItem?.lever,
        lotSize: curItem?.firstHand, // 一手等于
        isLong: curItem?.direction === 1, // 是否 开多
      });

  // 盈亏值
  const plAmount = isHistory
    ? curItem?.plAmount
    : // : getContractProfitAndLoss(curItem?.id)?.plAmount || curItem?.plAmount;
      calculateProfitLoss({
        marketPrice: soketData?.a || orderSocket?.currentPrice,
        openPrice: curItem?.endPrice,
        leverage: orderSocket?.lever ?? curItem?.lever,
        margin: curItem?.earnestMoney,
        isLong: curItem?.direction === 1, // 是否 开多
      });

  return (
    <div
      className="fixed  top-0 left-0 right-0 bottom-0 flex flex-col overflow-hidden bg-[#151225]"
      style={{
        backgroundImage: `url('${require('@/assets/img/contractSharingBg.jpg')}')`,
        backgroundRepeat: 'no-repeat',
        backgroundClip: 'content-box',
        backgroundSize: '100% 100%',
      }}
    >
      <div className="bg-background">
        <BaseTopNav title={t('分享')} />
      </div>
      <div className="relative flex-1 flex flex-col justify-between">
        <div className="h-[400px] " ref={posterRef}>
          <div className=" px-4 py-[24px]">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2 ">
                <div className="w-[30px] h-[30px]">
                  {appInfo?.APP_BASE_LOGO ? (
                    <img
                      src={appInfo?.APP_BASE_LOGO}
                      className="w-[30px] h-[30px] object-cover cursor-pointer  box-content"
                      alt="logo"
                      onClick={() => {
                        history.push(PageEnum.MINE);
                      }}
                    />
                  ) : (
                    <BasilUserSolid
                      className=" text-2xl text-titleColor"
                      onClick={() => {
                        history.push(PageEnum.MINE);
                      }}
                    />
                  )}
                </div>
                <div className="text-[#fff] text-base">
                  {appInfo?.COMPANY_NAME}
                </div>
              </div>
              <span>
                {isHistory ? (
                  // 历史的需要用平仓时间
                  <span className="text-auxiliaryTextColor text-xs">
                    {RenderUtil.formatDate(curItem?.completeTime)}
                  </span>
                ) : (
                  <span className="text-auxiliaryTextColor text-xs">
                    {RenderUtil.formatDate(curItem?.createTime)}
                  </span>
                )}
              </span>
            </div>
            <div className="absolute top-[36%] left-1/2 w-full -translate-y-1/2 -translate-x-1/2 flex flex-col items-center ">
              <div className="flex items-center gap-2">
                <div>
                  {user?.avatar ? (
                    <Image
                      className="w-[28px] h-[28px] flex-shrink-0 rounded-full object-cover"
                      src={user?.avatar}
                    />
                  ) : (
                    <Image
                      className="w-[28px] h-[28px] flex-shrink-0 rounded-full object-cover"
                      src={require('@/assets/img/defaultAvatar.png')}
                    />
                  )}
                </div>
                <div className="text-[#fff] text-[16px]">{user?.name}</div>
              </div>
              <div className="mt-[30px] flex justify-start  items-center ">
                <div className="text-[#fff] text-[20px] flex items-center  gap-2">
                  <span>{curItem?.name}</span>
                  <span className="break-normal">{t('永续合约')}</span>
                </div>
              </div>
              <div
                className="flex items-center gap-1 pt-2"
                style={{
                  fontSize: '16px',
                }}
              >
                {curItem?.direction === 1 && (
                  <div
                    className={cn('py-1 px-1 text-[#86909C]  rounded-[4px]')}
                  >
                    {currentTab === ContractOrderStatusEnum.IN_POSITION &&
                      t('多')}
                    {currentTab !== ContractOrderStatusEnum.IN_POSITION &&
                      curItem?.positionSwitchType === 0 &&
                      t('开多')}
                    {currentTab !== ContractOrderStatusEnum.IN_POSITION &&
                      curItem?.positionSwitchType === 1 &&
                      t('平多')}
                  </div>
                )}

                {curItem?.direction === 2 && (
                  <div
                    className={cn(
                      'py-1 relative text-[#86909C]  rounded-[4px] px-1',
                    )}
                  >
                    {currentTab === ContractOrderStatusEnum.IN_POSITION &&
                      t('空')}
                    {currentTab !== ContractOrderStatusEnum.IN_POSITION &&
                      curItem?.positionSwitchType === 0 &&
                      t('开空')}
                    {currentTab !== ContractOrderStatusEnum.IN_POSITION &&
                      curItem?.positionSwitchType === 1 &&
                      t('平空')}
                  </div>
                )}

                <div className="w-[1px] h-[12px] bg-[#86909C]"></div>
                {curItem?.earnestMode !== null && (
                  <>
                    <div className="py-1  text-[#86909C] rounded-[4px] px-1">
                      {curItem?.earnestMode === 1 ? t('全仓') : t('逐仓')}
                    </div>
                  </>
                )}
                <div className="w-[1px] h-[12px] bg-[#86909C]"></div>
                {curItem?.lever !== null && (
                  <div className="py-1  text-[#86909C] rounded-[4px] px-1">
                    {orderSocket?.lever ?? curItem?.lever}x
                  </div>
                )}
                <div className="w-[1px] h-[12px] bg-[#86909C]"></div>
                <div className="py-1  text-[#86909C] rounded-[4px] px-1">
                  {isHistory ? t('已平仓') : t('持仓中')}
                </div>
              </div>
              <div
                className={`text-[50px] mt-[32px] font-bold ${
                  plRatio > 0 ? currentUpClass : currentDownClass
                }`}
              >
                {plRatio > 0 ? '+' : ''}
                {RenderUtil.formatAndCeilToTwoDecimals(plRatio)}%
              </div>

              <div
                className={`text-[22px] mt-[10px]  font-bold ${
                  plAmount > 0 ? currentUpClass : currentDownClass
                }`}
              >
                {plAmount > 0 ? '+' : ''}
                {RenderUtil.FormatAmount(plAmount, 2)}
                {' USDT'}
              </div>

              <div className="mt-[24px] flex items-center gap-8">
                <div className="flex flex-col items-center">
                  <span className="text-[#86909C] text-[16px]">
                    {t('开仓均价')}
                  </span>
                  <span className="text-[#fff] pt-2 text-[22px]">
                    {RenderUtil.FormatAmount(
                      curItem?.endPrice,
                      priceAccuracy,
                      true,
                    )}
                  </span>
                </div>

                {(!curItem?.completePrice ||
                  +currentTab === +ContractOrderStatusEnum.IN_POSITION) && (
                  <div className="flex flex-col items-center">
                    <span className="text-[#86909C] text-[16px]">
                      {t('标记价格')}
                    </span>
                    <div className="pt-2 text-[#fff]  text-[22px]">
                      <ValueDisplay
                        className={'!text-[20px]'}
                        value={soketData?.a}
                        len={priceAccuracy}
                        isSubLen={false}
                      />
                    </div>
                  </div>
                )}

                {curItem?.completePrice &&
                  +currentTab !== +ContractOrderStatusEnum.IN_POSITION && (
                    <div className="flex flex-col items-center">
                      <span className="text-[#86909C] text-[16px]">
                        {t('平仓价格')}
                      </span>
                      <div className="pt-2 text-[#fff] text-[202x]">
                        {RenderUtil.FormatAmount(
                          curItem?.completePrice,
                          priceAccuracy,
                          true,
                        )}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <img
          src={require('@/assets/img/contractSharingThumbnail.png')}
          className="object-cover"
          alt=""
        />
      </div>
    </div>
  );
};
